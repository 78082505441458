import React, { useEffect, useState } from "react";
import { NextPage } from "next";

import {
  PublicProfileProvider,
  usePublicProfile,
} from "@contexts/publicProfile";
import {
  PageResourceType,
  PublicProfileUserData,
} from "@contexts/publicProfile/types";
import { useCustomOrDarkTheme } from "@contexts/theme";
import { useCheckScreenSize } from "@hooks/use-window-size";
import { AccountType } from "@lib/data/schemas/account";
import { getPublicPageServerSideProps as getServerSideProps } from "@lib/utils/server-side-props";

import MobileBanner from "@components/PublicProfile/MobileBanner";
import PublicProfilePage from "@components/PublicProfile/PublicProfilePage";
import PublicProfilePreview from "@components/PublicProfile/PublicProfilePreviewWrapper";
import PublicProfileSidebar from "@components/PublicProfile/PublicProfileSidebar";
import { PublicProfileSidebarPage } from "@components/PublicProfile/PublicProfileSidebar/Pages";
import { PublicItemType } from "@components/PublicProfile/PublicProfileSidebar/Pages/PublicItemCard";
import { getMemberResourceData } from "@components/PublicProfile/PublicProfileSidebar/utils";

export interface PageProps {
  data: PublicProfileUserData & {
    schedulers: PublicItemType[];
    packages: PublicItemType[];
    members: AccountType[];
  };
  editMode?: boolean;
}

const Page: NextPage<PageProps> = ({ data, editMode }) => {
  useCustomOrDarkTheme(data as Partial<PublicProfileUserData>);
  if (editMode) {
    return (
      <PublicProfileProvider>
        <EditPage data={data} />
      </PublicProfileProvider>
    );
  }
  return <PublicPage data={data} />;
};

const PublicPage = ({ data }: PageProps) => {
  if (!data) return null;
  const { schedulers, packages, members, ...user } = data;

  return (
    <PublicProfilePage
      user={user}
      schedulers={schedulers}
      packages={packages}
      members={members}
    />
  );
};

const EditPage = ({ data: initialData }: PageProps) => {
  const [selectedPage, setSelectedPage] = useState<PublicProfileSidebarPage>(
    PublicProfileSidebarPage.root
  );
  const { data, updateData, pageResource } = usePublicProfile();
  const { isLG } = useCheckScreenSize();

  useEffect(() => {
    updateData({
      ...initialData,
      schedulers: initialData.schedulers,
      packages: initialData.packages,
      members: initialData.members,
    });
  }, [initialData, updateData]);

  if (!data) return null;

  const { packages, schedulers, members, ...user } = data;
  const isNestedMember = !!pageResource?.isMemberResource;

  const {
    packages: memPackages,
    schedulers: memSchedulers,
    ...shownMember
  } = getMemberResourceData(pageResource as PageResourceType, data);

  const renderPublicProfile = () => (
    <PublicProfilePage
      user={isNestedMember ? shownMember : user}
      schedulers={isNestedMember ? memSchedulers : schedulers}
      packages={isNestedMember ? memPackages : packages}
      members={members}
      editMode
      isMemberPage={isNestedMember}
      setSelectedPage={setSelectedPage}
    />
  );

  if (!isLG) {
    return (
      <>
        {renderPublicProfile()}
        <div className="fixed w-full flex justify-center bottom-0 px-4">
          <MobileBanner className="my-4" />
        </div>
      </>
    );
  }

  return (
    <div className="h-screen w-full flex gap-4 p-4 bg-grey-950">
      <PublicProfileSidebar
        setSelectedPage={setSelectedPage}
        selectedPage={selectedPage}
      />
      <PublicProfilePreview>{renderPublicProfile()}</PublicProfilePreview>
    </div>
  );
};

export { getServerSideProps };

export default Page;
